<template>
  <el-main>
    <el-form label-width="140px">
      <el-form-item label="账户类型：">
        <el-radio-group v-model="editInfo.type" v-if="is_edit">
          <el-radio :label="1">对公账户</el-radio>
          <el-radio :label="2">对私账户</el-radio>
          <el-radio :label="3">微信</el-radio>
        </el-radio-group>
        <span v-else>{{ editInfo.type == 1 ? '对公账户' : editInfo.type == 2 ? '对私账户' : '微信' }}</span>
      </el-form-item>
      <el-form-item label="银行信息：" v-if="editInfo.type == 1 || editInfo.type == 2">
        <el-input v-model="editInfo.bank" v-if="is_edit"></el-input>
        <span v-else>{{ editInfo.bank }}</span>
      </el-form-item>
      <el-form-item label="公司账户：" v-if="editInfo.type == 1">
        <el-input v-model="editInfo.bank_card" v-if="is_edit"></el-input>
        <span v-else>{{ editInfo.bank_card }}</span>
      </el-form-item>
      <el-form-item label="公司名称：" v-if="editInfo.type == 1">
        <el-input v-model="editInfo.bank_name" v-if="is_edit"></el-input>
        <span v-else>{{ editInfo.bank_name }}</span>
      </el-form-item>
      <el-form-item label="银行卡号：" v-if="editInfo.type == 2">
        <el-input v-model="editInfo.bank_card1" v-if="is_edit"></el-input>
        <span v-else>{{ editInfo.bank_card1 }}</span>
      </el-form-item>
      <el-form-item label="持卡人姓名：" v-if="editInfo.type == 2">
        <el-input v-model="editInfo.bank_name1" v-if="is_edit"></el-input>
        <span v-else>{{ editInfo.bank_name1 }}</span>
      </el-form-item>
      <el-form-item label="微信到账用户：" v-if="editInfo.type == 3">
        <template v-if="is_edit">
          <el-input v-model="editInfo.nickname"></el-input>
          <el-button type="primary" @click="dialogVisible = !0">选择会员</el-button>
        </template>
        <span v-else>{{editInfo.nickname}}</span>
      </el-form-item>
      <el-form-item v-if="editInfo.type == 3 && editInfo.openid">
        <el-image style="width: 100px; height: 100px" :src="editInfo.avatar || require('@/assets/default-img.png')"></el-image>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="saveForm">{{ is_edit ? '保存' : '编辑' }}</el-button>
      </el-form-item>
    </el-form>
    <el-dialog title="选择会员" :visible.sync="dialogVisible" width="52%">
      <ListComponents :styleMethon="5" @getListItem="getItem"></ListComponents>
    </el-dialog>
  </el-main>
</template>

<script>
import ListComponents from '@/components/listComponents';

export default {
  components: {
    ListComponents,
  },
  data() {
    return {
      is_edit: !1,
      dialogVisible: !1,
      editInfo: {
        type: 1,
        bank: '',
        bank_name: '',
        bank_card: '',
        bank_name1: '',
        bank_card1: '',
        openid: '',
        avatar:'',
        nickname:''
      },
    };
  },
  created() {
    this.getAccountInfo();
  },
  methods: {
    getItem(item) {
      this.editInfo.openid = item.mini_openid;
      this.editInfo.avatar = item.avatar;
      this.editInfo.nickname = item.nickname;
      this.dialogVisible = false;
    },
    getAccountInfo() {
      this.$axios.post(this.$api.finance.accountInfo).then((res) => {
        if (res.code == 0) {
          let account = res.result;
          let editInfo = this.editInfo;
          if (account.type == 1) {
            editInfo.bank_name = account.bank_name;
            editInfo.bank_card = account.bank_card;
          } else if (account.type == 2) {
            editInfo.bank_name1 = account.bank_name;
            editInfo.bank_card1 = account.bank_card;
          } else {
            editInfo.avatar = account.avatar;
            editInfo.nickname = account.nickname;
            editInfo.openid = account.openid;
          }
          editInfo.bank = account.bank;
          editInfo.type = account.type;
          editInfo.id = account.id;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    saveForm() {
      if (this.is_edit) {
        let editInfo = this.editInfo;
        let data = {
          id: editInfo.id,
          type: editInfo.type,
        };
        if (editInfo.type == 1) {
          data.bank = editInfo.bank;
          data.bank_name = editInfo.bank_name;
          data.bank_card = editInfo.bank_card;
        } else if (editInfo.type == 2) {
          data.bank = editInfo.bank;
          data.bank_name = editInfo.bank_name1;
          data.bank_card = editInfo.bank_card1;
        } else {
          data.openid = editInfo.openid;
        }
        this.$axios.post(this.$api.finance.editAccount, data).then((res) => {
          if (res.code == 0) {
            this.is_edit = !1;
            this.$message.success('修改成功');
            this.getAccountInfo();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        this.is_edit = !0;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  height: 100%;
  .el-input {
    width: 500px;
  }
}
</style>
