<template>
  <div class="manyList">
    <div class="manyListtop">
      <el-input
        v-model="name"
        :placeholder="
          styleMethon == 0
            ? '请输入商品名称'
            : styleMethon == 1
            ? '请输入商户名称'
            : styleMethon == 2
            ? '请输入商户分类名称'
            : styleMethon == 3
            ? '请输入文章名称'
            : styleMethon == 4
            ? '请输入文章分类名称'
            : '选择会员'
        "
      ></el-input>
      <el-button type="primary" @click="getGoods">搜索</el-button>
    </div>
    <div class="manyListbot">
      <div v-for="(item, index) in list" :key="index">
        <div class="imgbox" v-if="styleMethon == 0 || styleMethon == 5">
          <img :src="styleMethon == 0 ? item.goods_picture : item.avatar ? item.avatar : require('@/assets/default-img.png')" alt="" />
        </div>
        <div class="name" v-if="styleMethon == 0 || styleMethon == 1 || styleMethon == 2 || styleMethon == 3 || styleMethon == 4 || styleMethon == 5">
          {{
            styleMethon == 0
              ? item.goods_name
              : styleMethon == 1
              ? item.store_name
              : styleMethon == 2
              ? item.class_name
              : styleMethon == 3
              ? item.title
              : styleMethon == 4
              ? item.classify_name
              : item.nickname
          }}
        </div>
        <div class="price" v-if="styleMethon == 0">￥{{ item.goods_price }}</div>
        <div class="choose" @click="chooseItem(item)">选择</div>
      </div>
    </div>
    <Paging :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
  </div>
</template>

<script>
import Paging from './paging'
export default {
  props: {
    styleMethon: {
      type: Number,
      default: () => -1
    }
  },
  components: {
    Paging
  },
  data() {
    return {
      name: '',
      list: [],
      page: 1,
      pageNum: 10,
      total: 0,
      flag: true
    }
  },
  created() {
    this.getGoods()
  },
  methods: {
    chooseItem(item) {
      this.$emit('getListItem', item)
    },
    // 分页参数
    updateData(val, status) {
      if (status == 0) {
        this.pageNum = val
      } else {
        this.page = val
      }
      if (this.flag) {
        this.getGoods()
      } else {
        this.getGoods(this.name)
      }
    },
    getGoods(val) {
      let that = this
      let url = ''
      if (this.styleMethon == 0) {
        url = this.$api.goods.list
      } else if (this.styleMethon == 1) {
        url = this.$api.store.storeList
      } else if (this.styleMethon == 3) {
        url = this.$api.set.listArticle
      } else if (this.styleMethon == 4) {
        url = this.$api.set.listArticleClass
      } else if (this.styleMethon == 5) {
        url = this.$api.finance.getWxOpenid
      }
      if (val) {
        this.flag = false
        if (this.styleMethon == 0) {
          this.$axios
            .post(url, {
              page: that.page,
              rows: that.pageNum,
              goods_name: this.name
            })
            .then((res) => {
              if (res.code == 0) {
                that.list = res.result.list
                that.total = res.result.total_number
              }
            })
        } else if (this.styleMethon == 1) {
          this.$axios
            .post(url, {
              page: that.page,
              rows: that.pageNum,
              store_name: this.name
            })
            .then((res) => {
              if (res && res.code == 0) {
                that.list = res.result.list
                that.total = res.result.total_number
              }
            })
        } else if (this.styleMethon == 3) {
          this.$axios
            .post(url, {
              page: that.page,
              rows: that.pageNum,
              title: this.name
            })
            .then((res) => {
              if (res && res.code == 0) {
                that.list = res.result.list
                that.total = res.result.total_number
              }
            })
        } else if (this.styleMethon == 4) {
          this.$axios
            .post(url, {
              page: that.page,
              rows: that.pageNum,
              classify_name: this.name
            })
            .then((res) => {
              if (res && res.code == 0) {
                that.list = res.result.list
                that.total = res.result.total_number
              }
            })
        } else if (this.styleMethon == 5) {
          this.$axios
            .post(url, {
              page: that.page,
              rows: that.pageNum,
              keyword: that.name
            })
            .then((res) => {
              if (res.code == 0) {
                that.list = res.result.list
                that.total = res.result.total
              }
            })
        }
      } else {
        this.$axios
          .post(url, {
            page: that.page,
            rows: that.pageNum
          })
          .then((res) => {
            if (res.code == 0) {
              that.list = res.result.list
              if (this.styleMethon == 5) {
                that.total = res.result.total
              } else {
                that.total = res.result.total_number
              }
            }
          })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.manyList {
  width: 100%;
  min-height: 400px;
  .manyListtop {
    display: flex;
  }
  .manyListbot {
    width: 100%;
    margin-top: 20px;
    height: 360px;
    overflow: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      /**/
    }
    &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #f9f9f9;
    }
    &::-webkit-scrollbar-corner {
      background: #204754;
    }
    & > div {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      min-height: 50px;
      .imgbox {
        width: 80px;
        height: 80px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        flex: 1;
        padding-left: 20px;
        height: 100%;
      }
      .price {
        width: 80px;
        height: 100%;
      }
      .choose {
        width: 80px;
        height: 100%;
        color: #3772ff;
        cursor: pointer;
      }
    }
  }
}
</style>
